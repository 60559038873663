import React from "react"
import Layout from "../../components/layout"
import BackButton from "../../components/BackBtn"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function Projecten() {
  return (
    <Layout>
      <Seo
        title="Documentaires"
        description="Bekijk hier al mijn foto documentaires, ingedeeld per thema."
      />

      <main className="main-content main-documentaires">
        <div className="title-container">
          <h1 className="main-title">Documentaires</h1>
          <BackButton path="../" text="Terug" />
        </div>
        <p className="main-description">
          Mijn hart ligt bij documentaire fotografie. Tegelijk vind ik het een
          enorme uitdaging, zeker het (leggen van) contact met mensen. Het gaat
          niet alleen om foto’s maken, maar om het verhaal. Dat vraagt veel
          voorbereiding en inleving.
        </p>
        <div className="card-container">
          <Link to="../Documentaires/werk-in-venetie" className="card__link">
            <div className="card">
              <StaticImage
                src="../../images/thumbs/werk-in-venetie.jpg"
                alt="Documentaires: Werk in Venetië"
                className="card-img"
              />
              <h5 className="card__title">Werk in Venetië</h5>
            </div>
          </Link>
          <Link to="../Documentaires/charleroi" className="card__link">
            <div className="card">
              <StaticImage
                src="../../images/thumbs/charleroi1.jpg"
                alt="Documentaires: Charleroi"
                className="card-img"
              />
              <h5 className="card__title">Charleroi</h5>
            </div>
          </Link>
          <Link to="../Documentaires/de-straat" className="card__link">
            <div className="card">
              <StaticImage
                src="../../images/thumbs/de-straat.jpg"
                alt="Documentaires: De Straat"
                className="card-img"
              />
              <h5 className="card__title">De Straat</h5>
            </div>
          </Link>
          <Link to="../Documentaires/wijkwijzer" className="card__link">
            <div className="card">
              <StaticImage
                src="../../images/thumbs/wijkwijzer.jpg"
                alt="Documentaires: Wijkwijzer Noordoost"
                className="card-img"
              />
              <h5 className="card__title">Wijkwijzer Noordoost</h5>
            </div>
          </Link>
        </div>
      </main>
    </Layout>
  )
}
